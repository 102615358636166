.loading-screen {
  --testOpacity: 0.5;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(
    to right,
    rgba(#fc6872, var(--testOpacity)),
    rgba(#fc956d, var(--testOpacity))
  );
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  text-transform: uppercase;
  color: #222;
  z-index: 1;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;

    span {
      display: flex;
      margin-left: 20px;
    }
  }
}

#glarePortal {
  user-select: none !important;
  pointer-events: none !important;
}

#glarePortal img {
  user-select: none !important;
  pointer-events: none !important;
}
