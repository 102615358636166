.main-site-logo {
  position: fixed;
  top: clamp(20px, calc(100vw * 0.02605), 50px);
  left: clamp(20px, calc(100vw * 0.02605), 50px);
  display: block;
  width: clamp(58px, calc(100vw * 0.046875), 90px);
  transition: opacity 0.2s ease-in-out;

  svg {
    width: 100%;
    height: auto;
  }

  &:hover {
    opacity: 0.6;
  }
}
