.event-popup {
  --imgSize: 80px;
  position: absolute;
  bottom: calc(100% + 10px);
  // Don't know why 11px, but it centers it perfectly on X
  left: calc(var(--width) * -0.5 - 11px);
  perspective: 1px;
  backface-visibility: hidden;
  // will-change: transform;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    height: 12.5px;
    width: 2px;
    background-color: white;
  }

  &--rhombus:after {
    height: 15.5px;
  }
  &--circle:after {
    height: 14px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    h4 {
      margin-right: auto;
      line-height: 1.15;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 12px;

      // prevents hover bug
      z-index: 9;

      &__desc {
        font-size: 10px;
        line-height: 1.4;
        $color: var(--bgColor);
        // font-smooth: antialiased;

        p {
          position: relative;
          // -webkit-font-smoothing: antialiased;
          // -moz-osx-font-smoothing: unset;
          // transform: perspective(1px) scale(1.1);
        }

        &-outer {
          position: relative;
          overflow: hidden;

          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 20px;
            left: 0;
            background: black;
            opacity: 0;
            pointer-events: none;
          }

          &:before {
            top: 0;
            background: linear-gradient(
              to bottom,
              rgba(var(--bgColor), 1) 0%,
              rgba(var(--bgColor), 0.738) 19%,
              rgba(var(--bgColor), 0.541) 34%,
              rgba(var(--bgColor), 0.382) 47%,
              rgba(var(--bgColor), 0.278) 56.5%,
              rgba(var(--bgColor), 0.194) 65%,
              rgba(var(--bgColor), 0.126) 73%,
              rgba(var(--bgColor), 0.075) 80.2%,
              rgba(var(--bgColor), 0.042) 86.1%,
              rgba(var(--bgColor), 0.021) 91%,
              rgba(var(--bgColor), 0.008) 95.2%,
              rgba(var(--bgColor), 0.002) 98.2%,
              rgba(var(--bgColor), 0) 100%
            );
          }
          &:after {
            bottom: 0;
            background: linear-gradient(
              to top,
              rgba(var(--bgColor), 1) 0%,
              rgba(var(--bgColor), 0.738) 19%,
              rgba(var(--bgColor), 0.541) 34%,
              rgba(var(--bgColor), 0.382) 47%,
              rgba(var(--bgColor), 0.278) 56.5%,
              rgba(var(--bgColor), 0.194) 65%,
              rgba(var(--bgColor), 0.126) 73%,
              rgba(var(--bgColor), 0.075) 80.2%,
              rgba(var(--bgColor), 0.042) 86.1%,
              rgba(var(--bgColor), 0.021) 91%,
              rgba(var(--bgColor), 0.008) 95.2%,
              rgba(var(--bgColor), 0.002) 98.2%,
              rgba(var(--bgColor), 0) 100%
            );
          }

          &.top-fade:before {
            opacity: 1;
          }
          &.btm-fade:after {
            opacity: 1;
          }
        }

        &-wrap {
          max-height: var(--imgSize);
          overflow: hidden;
          overflow-y: auto;
          padding-right: 9px;
          margin-right: 3px;

          &::-webkit-scrollbar {
            width: 4px;
            max-height: 189px;
          }
          &::-webkit-scrollbar-track {
            background: rgba(255, 227, 214, 0.2);
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(255, 227, 214, 0.9);
          }
          &::-webkit-scrollbar-thumb:hover {
            background: rgba(255, 227, 214, 1);
          }
        }

        p {
          font-size: inherit;
          line-height: inherit;
        }
      }

      img {
        object-fit: cover;
        width: var(--imgSize);
        height: var(--imgSize);
      }
    }

    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #fff;
      text-decoration: none;
      min-width: 140px;
      padding: 7px 12px 5px;
      font-size: 12px;
      color: #222;
      box-shadow: 3px 3px 0 0 rgba(#000, 0.3);
      transition: all 0.2s ease-in-out;

      &:hover {
        box-shadow: 0 0 0 0 rgba(#000, 0.3);
      }
    }
  }

  &__img-wrap {
    position: relative;
    flex-shrink: 0;
    width: var(--imgSize);
    height: var(--imgSize);

    &__loader {
      --loaderSize: 30px;

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      svg {
        content: '';
        position: absolute;
        top: calc(50% - var(--loaderSize) * 0.5);
        left: calc(50% - var(--loaderSize) * 0.5);
        width: var(--loaderSize);
        height: var(--loaderSize);
      }
    }
  }
}
