.panel {
  --borderWidth: 2px;
  --closeOffset: 5px;
  --closeWidth: 25px;
  --edgeWidth: 20px;
  --paddOff: 0.7;

  width: calc(var(--width) + var(--edgeWidth));
  position: relative;
  background: rgba(var(--bgColor), var(--opacity));
  border: var(--borderWidth) solid var(--main-color-hex);
  padding: var(--edgeWidth);
  padding-top: 0;
  border-top: 0;
  transform-origin: center bottom;
  // opacity: 0.3 !important;

  &:before {
    position: absolute;
    content: '';
    background: rgba(var(--bgColor), var(--opacity));
    bottom: 100%;
    left: calc(var(--borderWidth) * -1);
    width: var(--width);
    height: var(--edgeWidth);
    border-top: var(--borderWidth) solid var(--main-color-hex);
    border-left: var(--borderWidth) solid var(--main-color-hex);
  }

  .panel__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: calc(100% + var(--closeOffset));
    left: calc(var(--width) - var(--borderWidth) + var(--closeOffset));
    width: var(--closeWidth);
    height: var(--closeWidth);
    border: 2px solid var(--main-color-hex);
    background-color: transparent;
    transition: all 0.2s ease-in-out;

    svg {
      width: 13px;

      path {
        transition: all 0.2s ease-in-out;
      }
    }

    &:hover {
      border-color: #fff;

      svg path {
        fill: #fff;
        stroke: #fff;
      }
    }
  }
}
