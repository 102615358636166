.machine-toggle-btn {
  position: absolute;
  bottom: 70%;
  left: calc(50% - 10px);
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 0;
  background: transparent;
}
