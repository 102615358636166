.categories-filter {
  position: fixed;
  top: 25vh;
  right: clamp(20px, calc(100vw * 0.0364584), 70px);
  z-index: 1;

  h4 {
    font-weight: 600;
    font-size: clamp(18px, calc(100vw * 0.01302083333), 25px);
    color: #fff;
    line-height: 1;
    margin-bottom: clamp(10px, calc(100vw * 0.00729166666), 14px);
  }

  &__item {
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: clamp(140px, calc(100vw * 0.09895833333), 190px);
    height: clamp(50px, calc(100vw * 0.03125), 60px);
    background-color: rgba(255, 255, 255, 0.3);
    color: #fff;
    font-size: clamp(14px, calc(100vw * 0.009375), 18px);
    font-weight: 500;
    transition: all 0.2s ease-in-out;

    &__text {
      padding-top: 4px;
      text-align: left;
      width: 42%;
    }

    .category-icn--wrap {
      margin-right: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:hover {
      box-shadow: inset 0 0 0 4px #fff;
    }

    &.active {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

#categoriesPortal {
  z-index: 1;
}
