.arrows-wrap {
  pointer-events: none;
}

.arrows-wrap__inner {
  position: relative;
  display: flex;
  transform: translate(-50%, -50%);
  flex-direction: row-reverse;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    background: transparent;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: 10px;
    transform: skewY(339deg);
    z-index: 1;
  }
}

.arrow-btn {
  position: relative;
  top: 2px;
  width: clamp(40px, calc(100vw * 0.03645833333), 70px);
  height: clamp(30px, calc(100vw * 0.03125), 60px);
  border-radius: 0;
  border: 0;
  background: transparent;
  pointer-events: all;
  // box-shadow: 0 0 0 1px red;

  &--prev {
    transform-origin: right;
    transform: skewY(337deg) translateY(-2px);
  }
  &--next {
    transform-origin: left;
    transform: skewY(334deg) translateY(-3px) rotateY(323deg);
  }
}
