.time-machine {
  padding: calc(var(--edgeWidth) + 5px) !important;
  padding-top: 5px !important;

  .submit {
    --btnColor: var(--main-color-hex);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 25%;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    padding: 9px 6px 6px;
    margin-left: 20px;
    border: 0;
    box-shadow: 0 0 0 2px var(--btnColor);
    background: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      --btnColor: #fff;
    }
  }

  input {
    position: relative;
    border: 0;
    width: 100%;
    background-color: transparent;
    font-size: 44px;
    font-weight: 700;
    letter-spacing: 12px;
    text-align: center;
    border-bottom: 2px solid rgba(255, 255, 255, 0.7);
    color: var(--main-color-hex);
    outline: none;
    padding-left: 15px;
  }

  form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
  }
}
