#logoPortal {
  position: relative;
  z-index: 10;
}

.timeline-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 50px 70px;
}

.timeline-logo {
  position: fixed;
  top: clamp(20px, calc(100vw * 0.02605), 50px);
  left: 50%;
  transform: translateX(-50%);
  width: clamp(144px, calc(100vw * 0.1198), 230px);
  display: block;
  margin-left: auto;
  margin-right: auto;
}
