.filter-dropdown {
  position: relative;
  --opacity: 0.2;
  --offsetWidth: 31px;
  --offsetBefore: 6px;

  &__results {
    position: absolute;
    top: 77px;
    left: calc(var(--offsetWidth) * -1 + var(--offsetBefore));
    background: rgba(255, 227, 214, var(--opacity));
    width: calc(100% + 120px);
    box-shadow: 0 0 0 2px var(--main-color-hex);
    padding: 10px;
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
      max-height: 189px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 227, 214, 0.2);
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 227, 214, 0.9);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 227, 214, 1);
    }
  }

  &__result {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: transparent;
    box-shadow: 0 0 0 1px var(--main-color-hex);
    border: 0;
    height: 32px;
    padding-top: 7px;
    font-size: 18px;

    &:hover {
      color: #fff;
    }

    &:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }
}
