*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 1;
}

@media (min-width: 1200px) {
  body {
    overflow-x: hidden;
  }
}

button,
input {
  font-family: inherit; /* 1 */
}

#root {
  touch-action: none;
}

@font-face {
  font-family: 'GroteskBold';
  src: url('./assets/fontsFiles/Px-Grotesk-Bold.eot');
  src: url('./assets/fontsFiles/Px-Grotesk-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fontsFiles/Px-Grotesk-Bold.woff2') format('woff2'),
    url('./assets/fontsFiles/Px-Grotesk-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: GroteskBold, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;

  --main-color-hex: #ffe3d6;
  --main-color-rgb: rgb(255, 227, 214);
}

body::-webkit-scrollbar {
  width: 0px !important;
}

#root {
  position: relative;
  z-index: 1;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.category-icn {
  position: relative;
  background: #fff;
  width: clamp(26px, calc(100vw * 0.01666666666), 32px);
  height: clamp(26px, calc(100vw * 0.01666666666), 32px);
}

.category-icn--rhombus {
  transform: rotate(45deg) scale(0.9);
}

.category-icn--circle {
  transform: scale(1.1);
  border-radius: 50%;
}

body {
  background-image: linear-gradient(to right, #fc6872, #fc956d);
}

#glarePortal {
  pointer-events: none !important;
  user-select: none !important;
}

#pinHoverPortal {
  pointer-events: none !important;
  user-select: none !important;
}

.app-error {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(to right, #fc6872, #fc956d);
  z-index: 99999999999;
}

.app-error div {
  max-width: 700px;
  max-height: 500px;
  padding: 40px 50px;
  border: 1px solid crimson;
  background: rgba(255, 255, 255, 0.6);
}

.app-error h1 {
  margin-bottom: 0;
  color: #222;
  line-height: 1.3;
  text-align: center;
}

/* MOBILE FORM:START ---------------------------------------------- */
.mobileScreen {
  position: fixed;
  display: none !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.mobileScreen__wrap {
  display: flex;
  justify-content: center;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;

  overflow-y: auto;
}

.mobileScreen .mob-bg-img {
  background-image: url('./assets/img/mobBgcMin.png');
  width: 1920px;
  height: 969px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}

.mobileScreen__inner {
  position: relative;
  max-width: 330px;
  padding-left: 12px;
  padding-right: 12px;
  margin: auto;
  z-index: 2;
}
.mobileScreen__inner__logo {
  margin-bottom: 30px;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
}
.mobileScreen__inner__logo__svg {
  max-width: 100%;
  height: auto;
}

.mobileScreen__inner .form-wrap {
  position: relative;
}

.mobileScreen h2,
.mobileScreen h3,
.mobileScreen input,
.mobileScreen button {
  color: #fff;
}

.mobileScreen h2,
.mobileScreen h3 {
  text-align: center;
  line-height: 1.24;
}

.mobileScreen h2 {
  font-size: 22px;
  margin-bottom: 30px;
}

.mobileScreen h3 {
  font-size: 18px;
  margin-bottom: 20px;
}

.mobileScreen form {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

.mobileScreen form .hs-email label {
  display: none;
}

.mobileScreen form .hs-email {
  position: relative;
  min-width: calc(100% - 80px);
  height: 40px;
}

.mobileScreen form .input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mobileScreen form .hs-email input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 40px;
  border: 1px solid #fff !important;
  border-radius: 2px;
  padding: 6px 12px;
  font-size: 16px;
  outline: none !important;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.2s ease-in-out;
}

.mobileScreen form .hs-email input.error {
  border-color: rgb(168, 31, 58) !important;
}

.mobileScreen form .hs-email input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.mobileScreen form .hs-submit {
  position: relative;
  width: 80px;
  height: 40px;
  margin-left: 10px;
}

.mobileScreen form .hs-submit .actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 green;
}

.mobileScreen form ul,
.mobileScreen form ol {
  list-style: none;
}

.mobileScreen form [type='submit'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 0;
  font-size: 16px;
  padding-right: 10px;
  background: transparent;
  cursor: pointer !important;
  text-align: right;
  outline: none !important;
  transition: opacity 0.2s ease-in-out;
}

.mobileScreen form .cookie-reset-container {
  position: absolute;
  top: calc(100% + 30px);
  left: 0;
  color: #222;
  font-size: 12px;
  margin-bottom: 0;
}

.mobileScreen form .cookie-reset-container a {
  color: #222;
}

.mobileScreen .hs_error_rollup {
  position: absolute;
  top: calc(100% + 30px);
  left: 0;
  width: 100%;
}

.mobileScreen form .cookie-reset-container ~ .hs_error_rollup {
  top: calc(100% + 55px);
}

.mobileScreen .hs-error-msgs {
  color: rgb(168, 31, 58);
  font-size: 13px;
}

.mobileScreen .disclaimer {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  color: #222;
  font-size: 10px;
}

.mobileScreen .submitted-message {
  font-size: 20px;
  text-align: center;
  color: #fff;
  letter-spacing: 0.3px;
}

@media (min-width: 1200px) {
  .mobileScreen {
    display: none !important;
  }

  html {
    padding: 0;
    margin: 0;
  }

  body {
    /* To prevent rubber effect on OS */
    overflow: hidden;
    max-width: 100vw;
    max-height: 100vh;
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 1199px) {
  #logoPortal,
  #categoriesPortal,
  #root,
  #loadingPortal,
  #pointerPortal,
  #glarePortal,
  #timeMachinePortal,
  #popupPortal,
  #leftControlsArrowsPortal,
  #circlesPortal {
    /* Display none doesn't work as circles don't appear */
    opacity: 0;
    pointer-events: none;
  }

  .mobileScreen.form-loaded {
    display: flex !important;
  }

  .mobileScreen.form-loaded .mob-bg-img {
    opacity: 1;
  }

  #circlesPortal {
    z-index: 2;
  }

  body {
    min-height: 100vh;
    overflow: hidden;
  }
}

/* MOBILE FORM:END ------------------------------------------------ */
